html {
  height: 100%;
}

body {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: Helvetica, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f8f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'fontello';
  src: url(/static/media/fontello.6a9ae17f.eot);
  src: url(/static/media/fontello.6a9ae17f.eot#iefix)
      format('embedded-opentype'),
    url(/static/media/fontello.e36f163c.woff) format('woff'),
    url(/static/media/fontello.993ba0f4.ttf) format('truetype'),
    url(/static/media/fontello.333300b3.svg#fontello) format('svg');
  font-weight: normal;
  font-style: normal;
}

span.item-price {
  color: #4694fc !important;
}

.demo-icon {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* You can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.popover {
  background: white;
  width: 350px;
  border: 1px solid #cacaca;
  border-radius: 6px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
}

input[type='time']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

@-webkit-keyframes circle1 {
  0% {
    top: 0; }
  50% {
    top: 100px; }
  100% {
    top: 0px; } }

@keyframes circle1 {
  0% {
    top: 0; }
  50% {
    top: 100px; }
  100% {
    top: 0px; } }

@-webkit-keyframes circle2 {
  0% {
    top: 100px; }
  50% {
    top: 0px; }
  100% {
    top: 100px; } }

@keyframes circle2 {
  0% {
    top: 100px; }
  50% {
    top: 0px; }
  100% {
    top: 100px; } }

#startingLoader {
  color: white;
  background-color: #4694fc;
  height: 100%;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  #startingLoader div {
    margin: 150px auto; }
  #startingLoader .circle2 {
    height: 50px;
    width: 50px;
    background-color: white;
    border-radius: 50%;
    -webkit-animation: circle2 1.5s ease-in-out infinite;
            animation: circle2 1.5s ease-in-out infinite;
    position: absolute;
    left: 40%;
    right: 40%; }
  #startingLoader .circle1 {
    height: 50px;
    width: 50px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    left: 40%;
    right: 40%; }

