@keyframes circle1 {
  0% {
    top: 0;
  }

  50% {
    top: 100px;
  }

  100% {
    top: 0px;
  }
}
@keyframes circle2 {
  0% {
    top: 100px;
  }

  50% {
    top: 0px;
  }

  100% {
    top: 100px;
  }
}

#startingLoader {
  color: white;
  background-color: #4694fc;
  height: 100%;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  div {
    margin: 150px auto;
  }
  .circle2 {
    height: 50px;
    width: 50px;
    background-color: white;
    border-radius: 50%;
    animation: circle2 1.5s ease-in-out infinite;
    position: absolute;
    left: 40%;
    right: 40%;
  }
  .circle1 {
    height: 50px;
    width: 50px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    left: 40%;
    right: 40%;
  }
}
